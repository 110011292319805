.App{
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.App-logo{
	width: 10rem;
}

.flyout {
	display:flex;
	flex-direction: column;
	min-height:100vh;
	justify-content: space-between;
}

.home-feature-box .fa {
	font-size:6rem;
}

.home-feature-box span {
	display: block;
	color:#111;
	font-weight:bold;
	margin-top:1.5rem;
}

.example-components-list li > a {
  color: #495057;
}

.example-components-list li:last-child > a {
	border-bottom:0;
}

.example-components-list li > a .fa {
	color:rgba(0,0,0,.35);
	float:right;
}

.passwordModal{
	padding-left: 0 !important;
}

.passwordModal .modal-content{
	width: 50%;
	margin: auto;
}

.topGradient{
	background: linear-gradient(to right, rgba(98,125,77,1) 0%,rgba(31,59,8,1) 100%);
}
.loginCard{
    margin:auto;
}

.loginDiv .md-form{
    display:block;
    margin:auto;
}

.loginDiv .md-form input[type=text]:focus:not([readonly]), .loginDiv .md-form input[type=password]:focus:not([readonly]){
    border-bottom: 1px solid #42a5f5;
    box-shadow: 0 1px 0 0 #EEEEEE;
    color: #444444;
    text-align: end;

}

.loginDiv .md-form input[type=text]:focus:not([readonly]) + label, .loginDiv .md-form input[type=password]:focus:not([readonly]) + label{
    color:#42a5f5;
    font-size: 14px;
}


.loginDiv .md-form input[type=text]:not([readonly]), .loginDiv .md-form input[type=password]:not([readonly]){
    border-bottom: 1px solid #1976d2;
    box-shadow: 0 1px 0 0 #999999;
    color:#444444;
    text-align: end;
}

.loginDiv .md-form input[type=text]:not([readonly]) + label, .loginDiv .md-form input[type=password]:not([readonly]) + label{
    color:#444444;
    font-size: 14px;

}


.loginDiv .md-form .prefix.active {
    color: #444444;
}

.loginPageImage{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
}

.loginPageErrorText{
    color:red;
    font-size: 16px;
}



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    background-color: #000;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.dbLoadingSpinner{
    text-align: center;
    vertical-align: center;
}

.pvNoteModal{
    background-color: #ffffff;
}

.pvTherapistDropdown{
    margin-top: 1rem;
}

.pvRelationshipDropdown{
    margin-top: 1rem;
}

.pvInsuranceInputLabel{
    color:#607d8b;
}

.pvNoteContainer{
    display: inline-block;
    max-width: 40%;
    margin-left: 1rem;
    margin-right: 1rem;
    vertical-align: middle;
}

.pvNoteText{
    white-space: normal;
}

.pvNotesTabButton{
    text-align: center;
}

.pvNotesGroupOuterContainer{
    overflow-x: auto;
}

.pvNotesGroupInnerContainer{
    white-space: nowrap;
}

.pvModalBackground{
    background-color: transparent;
}

.informationCard .md-form input[type=text],  .md-form input[type=email], .md-form input[type=time], .md-form input[type=date], .md-form input[type=datetime-local], .md-form input[type=tel], .md-form input[type=number],  .md-form textarea.md-textarea{
    border: none;
    text-align: end;
}

.insuranceCard .md-form input[type=text],  .md-form input[type=email], .md-form input[type=time], .md-form input[type=date], .md-form input[type=datetime-local], .md-form input[type=tel], .md-form input[type=number],  .md-form textarea.md-textarea{
    border: none;
    text-align: end;
}

.checkBoxLabel{
    text-align: right;
}

.dropdown-menu{
    max-height: 300px;
    overflow-y: scroll;
}

.patientHeaderRear{
    color:#FFFFFF;
    text-align: end;
    font-size: 36px;
    position: absolute;
    right: 1.5em;
    top: 2.7rem;
}

.patientHeaderFront{
    color:#FFFFFF;
    text-align: start;
    font-size: 36px;
    position: absolute;
    left: 1.5em;
    top: 2rem;
}

.patientCardHeader{
    color:#FFFFFF;
    text-align: end;
    position: absolute;
    right: 1.5em;
}

.patientNameMargin{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.patientHeaderFront:hover, .patientHeaderRear:hover, .patientCardHeader:hover {
    color: #95df74;
}

.individualNote{
    justify-content: space-between;
}

.modal-dialog{
    width:40% !important;
    max-width: 40% !important;
    padding-left: 50px;
}

.modal-content{
    background-color: transparent;
}

div.modal-content{
    box-shadow:none !important;
}

.modal-body{
    border-radius: 2px;
}




/* Cascading modal register / login  */
.form-cascading .modal-footer{
    border-top: none;
    flex-direction: column;
}

.form-cascading #options {
    margin-right: auto;
}

.form-cascading #options p {
    margin-bottom: 0;
}

.form-cascading #options p span{
    cursor: pointer;
}

/* Elegant modal login */
.form-elegant .modal-header {
    border-bottom: none;
}

.form-elegant .modal-footer{
    border-top: none;
    flex-direction: column;
}

.form-elegant .font-small {
    font-size: 0.8rem;
}

/* Dark modal register */
.form-dark .modal-content{
    background-image: url('https://mdbootstrap.com/img/Photos/Others/pricing-table%20(7).jpg');
}

.form-dark .modal-header {
    border-bottom: none;
}

.form-dark .modal-header .close {
    color: #fff;
}

.form-dark .modal-footer{
    border-top: none;
    flex-direction: column;
}

.form-dark input[type=email]:focus:not([readonly]) {
    border-bottom: 1px solid #00C851;
    box-shadow: 0 1px 0 0 #00C851;
}

.form-dark input[type=email]:focus:not([readonly]) + label {
    color: #fff;
}

::-webkit-input-placeholder{
    color:#fff;
}

::placeholder{
    color:#fff;
}

.form-dark .font-small {
    font-size: 0.8rem;
}

.datePickerOptions .md-form{
    margin-top: 14px;
    margin-bottom: 14px;
}

.datePickerOptions .md-form label{
    margin-bottom: 8px;
    top: 0;
}

.datePickerOptions .md-form div{
    padding-left: 0;
    display:flex;
}

.datePickerOptions .md-form div::before{
    border-bottom-color: #CED4DA;
}

.patientListDate,
.patientListDate input,
.patientListDate .md-form input[type=text],

.patientListDate .md-form input,
.patientListDate .md-form p,
.patientListDate .md-form span, .patientListDate div::before {
    color:#FFFFFF;
    border-bottom-color: #96C93D;
}
.patientListDate .md-form label {
    color: #FFFFFF;
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
}

.patientListDate .md-form input[type=date]:focus,
.patientListDate .md-form input[type=text]:focus:not([readonly]),
.patientListDate .md-form input[type=date]:focus:not([readonly]){
    color:#FFFFFF;
    border-bottom: none;
    box-shadow: none;
    /*border-bottom-color: #FFFFFF;*/
    /*box-shadow:0 1px 0 0 #EEEEEE;*/
}

.patientListDate .md-form div>div>div::after{
    border-bottom-color: #FFFFFF;
    -webkit-transform: none;
            transform: none;
}

.patientListName,
.patientListName input,
.patientListName .md-form input[type=text],
.patientListName .md-form label{
    color:#FFFFFF;
    border-color: #96C93D;
    margin-top: 1rem;
}

.patientListName .md-form input:hover{
    border-bottom-color: #000000;
    box-shadow:0 1px 0 0 #000000;
}

.patientListName input:focus,
.patientListName .md-form input[type=text]:focus,
.patientListName .md-form input[type=text]:focus:not([readonly]){
    color:#FFFFFF;
    border-bottom-color: #FFFFFF;
    box-shadow:0 1px 0 0 #EEEEEE;
}

.patientListName .md-form input[type=text]:focus:not([readonly]) + label{
    color:#FFFFFF;
}

.patientListNameBanner,
.patientListNameBanner input,
.patientListNameBanner .md-form input[type=text],
.patientListNameBanner .md-form label{
    color:#FFFFFF;
    border-color: #96C93D;


}

.patientListNameBanner .md-form input:hover{
    border-bottom-color: #000000;
    box-shadow:0 1px 0 0 #000000;
}

.patientListNameBanner input:focus,
.patientListNameBanner .md-form input[type=text]:focus,
.patientListNameBanner .md-form input[type=text]:focus:not([readonly]){
    color:#FFFFFF;
    border-bottom-color: #FFFFFF;
    box-shadow:0 1px 0 0 #EEEEEE;
}

.patientListNameBanner .md-form input[type=text]:focus:not([readonly]) + label{
    color:#FFFFFF;
}

.md-form input[type=text], .md-form input[type=date], .md-form input[type=password]{
    color: #607d8b;
    border-bottom-color: #607d8b;
}

.datePickerOptions .md-form div::before{
    border-bottom-color: #607d8b;
}

.md-form input[type=text]:focus:not([readonly]), .md-form input[type=date]:focus:not([readonly]){
    border-bottom: 1px solid #455a64;
    box-shadow:0 1px 0 0 #455a64;
}

 .md-form label{
     color: #607d8b;
 }

.md-form input[type=text]:focus:not([readonly]) + label{
    color:#455a64;
}

.pvButtonFloat{
    float:right;
}

.equipmentText{
    font-family: Arial;
    color: #455a64;
    font-weight: bold;
}

.searchBoxPt{
    background-color: #96C93D !important;
}

/*Home*/

.patientSearchDiv .md-form input[type=text]{
    font-size: 26px;
    /*color: #DDDDDD;*/
}

.patientSearchDiv .md-form input[type=text]:focus:not([readonly]){
    border-bottom: 2px solid #ff0000;
    box-shadow: 0 1px 0 0 #DDDDDD;
}

.patientSearchDiv ::-webkit-input-placeholder{
    /*color:#FFFFFF;*/
    opacity: .7;
}

.patientSearchDiv ::placeholder{
    /*color:#FFFFFF;*/
    opacity: .7;
}

.homeTimelineModalBody{
    background-color: transparent;
}

.homeNoteCardSpacing{
    margin-bottom: 2rem;
}

.ageButtonBackgroundColor{
    background-color: #4F4F4F !important;
}

.ageButtonBackgroundColorPriority{
    background-color: #d32f2f !important;
}

.statusButtonIntake{
    background-color: #2C4163 !important;
}

.statusButtonMedical{
    background-color: #2C4163 !important;
}

.statusButtonSignature{
    background-color:#2C4163 !important;
}

.statusButtonInsurance{
    /*background-color: #1e88e5 !important;*/
    background-color: #2C4163 !important;
}

.statusButtonDelivery{
    background-color: #2C4163 !important;
}

.cardFilterColor{
    min-height: 105px!important;
    max-width: 160px!important;
    background-color: #96C93D !important;
}
.cardFilter{

}
/*End Home*/



/*CardList*/
.clIconSpacing{
    margin: auto;

}

.clIcon{
    color:#757575;
    font-size: 32px;
}

.clCardGroup{
    max-width: 96%;
    align-items: center;
    justify-content: space-around;
}

.clCardSizing{
    min-width: 237px;
    max-width: 237px;
    margin: 5px;
}

.clCardButtonGroup{
    width: 100%;
}

.nameRouteCard{
    margin: 0!important;
    border-radius: 0 0 5px 5px;
}

.clCardAgeButton{
    border-radius: 0;
    margin: 0;
    padding-left: 1px;
    padding-right: 1px;
    font-size: 20px;
    min-width: 73px;
}

.clStatusButton{
    max-height: 92px!important;
    border-radius: 0px;
    padding: 0px;
    flex-grow: 1 !important;
    font-size: 16px;
    margin: 0!important;
}

.clCardBodyLeftCol{
    border-right:1px solid #616161;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
}


/*End CardList*/


/*Dashboard*/

.dbNoteHeader{
    display: flex;
    justify-content: space-between;
}

.dbNoteCompleteButton{
    float: right;
}

.dbPriorityPatientContainer{
    margin-bottom: .5rem
}

.dbPriorityPatientHeader{
    font-size: 26px;
}

.dbPriorityPatientRouteButton{
    float:right;
    font-size: 26px;
}

.dbStatusCards{
    width:100%;
    height:100%;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.dbStatusNumber{
    font-size: 80px;
}

.dbStatusText{
    margin-top: 1rem;
}

.dbLowerSpacing{
    margin-bottom: .5rem;
}

.dbMainContainer{
    /*margin-top: 5%;*/
    min-width: 1050px;
}

.dbLoadingSpinner{
    text-align: center;
    vertical-align: center;
}

/*End Dashboard*/


.w-90{
    width: 90% !important;
}

.verticalPadding{
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.preloader-wrapper {
    width: 8rem;
    height: 8rem;
}

.headerGradient{
    background: linear-gradient(to right, rgba(98,125,77,1) 0%,rgba(31,59,8,1) 100%);
}

.searchBanner{


    padding: 0;
    /*padding-top: 25px;*/
    max-height: 100px;
    /*transform: translateY(-3.2em);*/
    width: 100%;
    background-color: #2C4163;
}
.searchRow{
    /*justify-content: start!important;*/
    align-self: center;
}
