.loginCard{
    margin:auto;
}

.loginDiv .md-form{
    display:block;
    margin:auto;
}

.loginDiv .md-form input[type=text]:focus:not([readonly]), .loginDiv .md-form input[type=password]:focus:not([readonly]){
    border-bottom: 1px solid #42a5f5;
    box-shadow: 0 1px 0 0 #EEEEEE;
    color: #444444;
    text-align: end;

}

.loginDiv .md-form input[type=text]:focus:not([readonly]) + label, .loginDiv .md-form input[type=password]:focus:not([readonly]) + label{
    color:#42a5f5;
    font-size: 14px;
}


.loginDiv .md-form input[type=text]:not([readonly]), .loginDiv .md-form input[type=password]:not([readonly]){
    border-bottom: 1px solid #1976d2;
    box-shadow: 0 1px 0 0 #999999;
    color:#444444;
    text-align: end;
}

.loginDiv .md-form input[type=text]:not([readonly]) + label, .loginDiv .md-form input[type=password]:not([readonly]) + label{
    color:#444444;
    font-size: 14px;

}


.loginDiv .md-form .prefix.active {
    color: #444444;
}

.loginPageImage{
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
}

.loginPageErrorText{
    color:red;
    font-size: 16px;
}



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    background-color: #000;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}