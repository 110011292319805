
.dbLoadingSpinner{
    text-align: center;
    vertical-align: center;
}

.pvNoteModal{
    background-color: #ffffff;
}

.pvTherapistDropdown{
    margin-top: 1rem;
}

.pvRelationshipDropdown{
    margin-top: 1rem;
}

.pvInsuranceInputLabel{
    color:#607d8b;
}

.pvNoteContainer{
    display: inline-block;
    max-width: 40%;
    margin-left: 1rem;
    margin-right: 1rem;
    vertical-align: middle;
}

.pvNoteText{
    white-space: normal;
}

.pvNotesTabButton{
    text-align: center;
}

.pvNotesGroupOuterContainer{
    overflow-x: auto;
}

.pvNotesGroupInnerContainer{
    white-space: nowrap;
}

.pvModalBackground{
    background-color: transparent;
}

.informationCard .md-form input[type=text],  .md-form input[type=email], .md-form input[type=time], .md-form input[type=date], .md-form input[type=datetime-local], .md-form input[type=tel], .md-form input[type=number],  .md-form textarea.md-textarea{
    border: none;
    text-align: end;
}

.insuranceCard .md-form input[type=text],  .md-form input[type=email], .md-form input[type=time], .md-form input[type=date], .md-form input[type=datetime-local], .md-form input[type=tel], .md-form input[type=number],  .md-form textarea.md-textarea{
    border: none;
    text-align: end;
}

.checkBoxLabel{
    text-align: right;
}

.dropdown-menu{
    max-height: 300px;
    overflow-y: scroll;
}

.patientHeaderRear{
    color:#FFFFFF;
    text-align: end;
    font-size: 36px;
    position: absolute;
    right: 1.5em;
    top: 2.7rem;
}

.patientHeaderFront{
    color:#FFFFFF;
    text-align: start;
    font-size: 36px;
    position: absolute;
    left: 1.5em;
    top: 2rem;
}

.patientCardHeader{
    color:#FFFFFF;
    text-align: end;
    position: absolute;
    right: 1.5em;
}

.patientNameMargin{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.patientHeaderFront:hover, .patientHeaderRear:hover, .patientCardHeader:hover {
    color: #95df74;
}

.individualNote{
    justify-content: space-between;
}

.modal-dialog{
    width:40% !important;
    max-width: 40% !important;
    padding-left: 50px;
}

.modal-content{
    background-color: transparent;
}

div.modal-content{
    box-shadow:none !important;
}

.modal-body{
    border-radius: 2px;
}




/* Cascading modal register / login  */
.form-cascading .modal-footer{
    border-top: none;
    flex-direction: column;
}

.form-cascading #options {
    margin-right: auto;
}

.form-cascading #options p {
    margin-bottom: 0;
}

.form-cascading #options p span{
    cursor: pointer;
}

/* Elegant modal login */
.form-elegant .modal-header {
    border-bottom: none;
}

.form-elegant .modal-footer{
    border-top: none;
    flex-direction: column;
}

.form-elegant .font-small {
    font-size: 0.8rem;
}

/* Dark modal register */
.form-dark .modal-content{
    background-image: url('https://mdbootstrap.com/img/Photos/Others/pricing-table%20(7).jpg');
}

.form-dark .modal-header {
    border-bottom: none;
}

.form-dark .modal-header .close {
    color: #fff;
}

.form-dark .modal-footer{
    border-top: none;
    flex-direction: column;
}

.form-dark input[type=email]:focus:not([readonly]) {
    border-bottom: 1px solid #00C851;
    -webkit-box-shadow: 0 1px 0 0 #00C851;
    box-shadow: 0 1px 0 0 #00C851;
}

.form-dark input[type=email]:focus:not([readonly]) + label {
    color: #fff;
}

::placeholder{
    color:#fff;
}

.form-dark .font-small {
    font-size: 0.8rem;
}

.datePickerOptions .md-form{
    margin-top: 14px;
    margin-bottom: 14px;
}

.datePickerOptions .md-form label{
    margin-bottom: 8px;
    top: 0;
}

.datePickerOptions .md-form div{
    padding-left: 0;
    display:flex;
}

.datePickerOptions .md-form div::before{
    border-bottom-color: #CED4DA;
}

.patientListDate,
.patientListDate input,
.patientListDate .md-form input[type=text],

.patientListDate .md-form input,
.patientListDate .md-form p,
.patientListDate .md-form span, .patientListDate div::before {
    color:#FFFFFF;
    border-bottom-color: #96C93D;
}
.patientListDate .md-form label {
    color: #FFFFFF;
    transform: translateY(8px);
}

.patientListDate .md-form input[type=date]:focus,
.patientListDate .md-form input[type=text]:focus:not([readonly]),
.patientListDate .md-form input[type=date]:focus:not([readonly]){
    color:#FFFFFF;
    border-bottom: none;
    box-shadow: none;
    /*border-bottom-color: #FFFFFF;*/
    /*box-shadow:0 1px 0 0 #EEEEEE;*/
}

.patientListDate .md-form div>div>div::after{
    border-bottom-color: #FFFFFF;
    transform: none;
}

.patientListName,
.patientListName input,
.patientListName .md-form input[type=text],
.patientListName .md-form label{
    color:#FFFFFF;
    border-color: #96C93D;
    margin-top: 1rem;
}

.patientListName .md-form input:hover{
    border-bottom-color: #000000;
    box-shadow:0 1px 0 0 #000000;
}

.patientListName input:focus,
.patientListName .md-form input[type=text]:focus,
.patientListName .md-form input[type=text]:focus:not([readonly]){
    color:#FFFFFF;
    border-bottom-color: #FFFFFF;
    box-shadow:0 1px 0 0 #EEEEEE;
}

.patientListName .md-form input[type=text]:focus:not([readonly]) + label{
    color:#FFFFFF;
}

.patientListNameBanner,
.patientListNameBanner input,
.patientListNameBanner .md-form input[type=text],
.patientListNameBanner .md-form label{
    color:#FFFFFF;
    border-color: #96C93D;


}

.patientListNameBanner .md-form input:hover{
    border-bottom-color: #000000;
    box-shadow:0 1px 0 0 #000000;
}

.patientListNameBanner input:focus,
.patientListNameBanner .md-form input[type=text]:focus,
.patientListNameBanner .md-form input[type=text]:focus:not([readonly]){
    color:#FFFFFF;
    border-bottom-color: #FFFFFF;
    box-shadow:0 1px 0 0 #EEEEEE;
}

.patientListNameBanner .md-form input[type=text]:focus:not([readonly]) + label{
    color:#FFFFFF;
}

.md-form input[type=text], .md-form input[type=date], .md-form input[type=password]{
    color: #607d8b;
    border-bottom-color: #607d8b;
}

.datePickerOptions .md-form div::before{
    border-bottom-color: #607d8b;
}

.md-form input[type=text]:focus:not([readonly]), .md-form input[type=date]:focus:not([readonly]){
    border-bottom: 1px solid #455a64;
    box-shadow:0 1px 0 0 #455a64;
}

 .md-form label{
     color: #607d8b;
 }

.md-form input[type=text]:focus:not([readonly]) + label{
    color:#455a64;
}

.pvButtonFloat{
    float:right;
}

.equipmentText{
    font-family: Arial;
    color: #455a64;
    font-weight: bold;
}

.searchBoxPt{
    background-color: #96C93D !important;
}