
/*Home*/

.patientSearchDiv .md-form input[type=text]{
    font-size: 26px;
    /*color: #DDDDDD;*/
}

.patientSearchDiv .md-form input[type=text]:focus:not([readonly]){
    border-bottom: 2px solid #ff0000;
    box-shadow: 0 1px 0 0 #DDDDDD;
}

.patientSearchDiv ::placeholder{
    /*color:#FFFFFF;*/
    opacity: .7;
}

.homeTimelineModalBody{
    background-color: transparent;
}

.homeNoteCardSpacing{
    margin-bottom: 2rem;
}

.ageButtonBackgroundColor{
    background-color: #4F4F4F !important;
}

.ageButtonBackgroundColorPriority{
    background-color: #d32f2f !important;
}

.statusButtonIntake{
    background-color: #2C4163 !important;
}

.statusButtonMedical{
    background-color: #2C4163 !important;
}

.statusButtonSignature{
    background-color:#2C4163 !important;
}

.statusButtonInsurance{
    /*background-color: #1e88e5 !important;*/
    background-color: #2C4163 !important;
}

.statusButtonDelivery{
    background-color: #2C4163 !important;
}

.cardFilterColor{
    min-height: 105px!important;
    max-width: 160px!important;
    background-color: #96C93D !important;
}
.cardFilter{

}
/*End Home*/



/*CardList*/
.clIconSpacing{
    margin: auto;

}

.clIcon{
    color:#757575;
    font-size: 32px;
}

.clCardGroup{
    max-width: 96%;
    align-items: center;
    justify-content: space-around;
}

.clCardSizing{
    min-width: 237px;
    max-width: 237px;
    margin: 5px;
}

.clCardButtonGroup{
    width: 100%;
}

.nameRouteCard{
    margin: 0!important;
    border-radius: 0 0 5px 5px;
}

.clCardAgeButton{
    border-radius: 0;
    margin: 0;
    padding-left: 1px;
    padding-right: 1px;
    font-size: 20px;
    min-width: 73px;
}

.clStatusButton{
    max-height: 92px!important;
    border-radius: 0px;
    padding: 0px;
    flex-grow: 1 !important;
    font-size: 16px;
    margin: 0!important;
}

.clCardBodyLeftCol{
    border-right:1px solid #616161;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
}


/*End CardList*/


/*Dashboard*/

.dbNoteHeader{
    display: flex;
    justify-content: space-between;
}

.dbNoteCompleteButton{
    float: right;
}

.dbPriorityPatientContainer{
    margin-bottom: .5rem
}

.dbPriorityPatientHeader{
    font-size: 26px;
}

.dbPriorityPatientRouteButton{
    float:right;
    font-size: 26px;
}

.dbStatusCards{
    width:100%;
    height:100%;
    text-align: center;
    color: #FFFFFF;
    cursor: pointer;
}

.dbStatusNumber{
    font-size: 80px;
}

.dbStatusText{
    margin-top: 1rem;
}

.dbLowerSpacing{
    margin-bottom: .5rem;
}

.dbMainContainer{
    /*margin-top: 5%;*/
    min-width: 1050px;
}

.dbLoadingSpinner{
    text-align: center;
    vertical-align: center;
}

/*End Dashboard*/


.w-90{
    width: 90% !important;
}

.verticalPadding{
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.preloader-wrapper {
    width: 8rem;
    height: 8rem;
}

.headerGradient{
    background: linear-gradient(to right, rgba(98,125,77,1) 0%,rgba(31,59,8,1) 100%);
}

.searchBanner{


    padding: 0;
    /*padding-top: 25px;*/
    max-height: 100px;
    /*transform: translateY(-3.2em);*/
    width: 100%;
    background-color: #2C4163;
}
.searchRow{
    /*justify-content: start!important;*/
    align-self: center;
}